'use strict';

var formValidation = require('base/components/formValidation');
$(window).on('load', function () {

    setTimeout(function () {
        $('form.login').removeClass('d-none');
        $('form.registration').removeClass('d-none');
        $('.myAccountHeader-unlogged .form-group input').on('input', function() {
            $('form.login .alert-danger').fadeOut();
        });
        
        // submit login
        $('form.login').on('submit', function (e) {
            e.preventDefault();
            var form = $(this);
            var url = form.attr('action');
            var currentUrl = window.location.href;

            form.spinner().start();
            $('form.login').trigger('login:submit', e);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    // console.log(data);
                    form.spinner().stop();
                    if (!data.success) {
                        // console.log('data.success', data.success);
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        // console.log('data.success', data.success);
                        $('form.login').trigger('login:success', data);
                        //location.href = data.redirectUrl;
                        location.href = currentUrl;
                    }
                },
                error: function (data) {
                    // console.log(data);
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });

        // submit register
        $('form.registration').submit(function (e) {
            e.preventDefault();
            var form = $(this);
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });

        // submit reset password
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
        //remove skeleton login page
        $('.login-page .skeleton-loader, .myAccountHeader-unlogged .skeleton-loader').remove();
    }, 100);
   
});